





















import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class App extends Vue {
  title = "Vue Books";
  public authenticated: boolean = false;

  private created() {
    this.isAuthenticated();
  }
  
  @Watch('$route')
  private async isAuthenticated() {
    this.authenticated = await this.$auth.isAuthenticated();
  }

  private async logout() {
    await this.$auth.logout();
    await this.isAuthenticated();

    // Navigate back to home
    this.$router.push({path: '/'});
  }
}
